<template>
  <div>
    <Table :headers="headers" :items="items">
      <template #status="{ item }">
        <router-link :to="`/definitions/${item.title}`">
          <button class="donate btn-pill btn">Detaya Git</button>
        </router-link>
      </template>
    </Table>
  </div>
</template>

<script>
import Table from "../Tables/index.vue";
export default {
  components: {
    Table,
  },
  data() {
    return {
      headers: [
        {
          title: this.$t("title"),
          value: "title",
          col: 6,
          md: 6,
          lg: 6,
          xl: 6,
        },
        {
          title: this.$t("situation"),
          value: "status",
          col: 12,
          md: 3,
          lg: 6,
          xl: 6,
        },
      ],
      items: [{ title: "Register" }],
      loading: false,
    };
  },
};
</script>

<style scoped></style>
