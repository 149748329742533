<template>
  <div class="container-fluid">
    <div
      class="table-header d-none d-md-flex align-items-center"
      v-if="headers.length !== 0"
    >
      <div v-if="checkable || singleCheckable" class="col-1">
        <div v-if="checkable" class="chekbox-lg">
          <label>
            <b
              @click="
                $emit(
                  'change:checkedAll',
                  checkedList.length !== items.length
                    ? true
                    : checkedList.length === 0
                    ? true
                    : false
                )
              "
              :class="{
                'not-all': checkedList.length !== items.length,
                checked: checkedList.length !== 0,
              }"
              class="checkmark"
            ></b>
          </label>
        </div>
      </div>
      <div
        :class="`
      ${headers[0].col ? `col-${headers[0].col}` : ''}
      ${headers[0].sm ? `col-sm-${headers[0].sm}` : ''}
      ${headers[0].md ? `col-md-${headers[0].md}` : ''}
      ${headers[0].lg ? `col-lg-${headers[0].lg}` : ''}
      ${headers[0].xl ? `col-xl-${headers[0].xl}` : ''}
      ${headers[0].xxl ? `col-xxl-${headers[0].xxl}` : ''}
      ${scrollLeft > 10 ? 'shadow-right' : ''}

      `"
      >
        {{ headers[0].title }}
      </div>
      <div
        @scroll="SET_SCROLL_EVENT"
        id="table-header"
        class="d-flex w-100 align-items-center overflow-x-scroll"
      >
        <div
          v-for="(header, i) in headers.slice(1)"
          :key="'c' + i"
          :class="`
      ${header.col ? `col-${header.col}` : ''}
      ${header.sm ? `col-sm-${header.sm}` : ''}
      ${header.md ? `col-md-${header.md}` : ''}
      ${header.lg ? `col-lg-${header.lg}` : ''}
      ${header.xl ? `col-xl-${header.xl}` : ''}
      ${header.xxl ? `col-xxl-${header.xxl}` : ''}
      `"
        >
          {{ header.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      default: () => [],
    },
    items: {
      default: () => [],
    },
    checkedList: {
      default: () => [],
    },
    checkable: {
      default: false,
      type: Boolean,
    },
    singleCheckable: {
      default: false,
      type: Boolean,
    },
    allChecked: {
      default: false,
      type: Boolean,
    },
    scrollLeft: {
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    SET_SCROLL_EVENT(e) {
      const bodies = document.querySelectorAll(".table-body-scroll");
      for (let i = 0; i < bodies.length; i++) {
        const el = bodies[i];
        el.scrollLeft = e.target.scrollLeft;
      }
      this.$emit("change:scroll", e.target.scrollLeft);
    },
  },
};
</script>

<style></style>
