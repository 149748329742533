<template>
  <div>
    <div
      class="table-body py-4 py-md-0"
      :class="{ inside: hideHeader }"
      v-for="(item, i) in items"
      :key="'b' + i"
    >
      <div v-if="$scopedSlots.options" class="table-options">
        <slot :item="item" :index="i" name="options"></slot>
      </div>
      <div v-if="checkable || singleCheckable" class="col-1">
        <div class="chekbox-lg">
          <label>
            <input
              @change="$emit('change:checked', item)"
              type="checkbox"
              :checked="
                checkedList.find((c) => c[checkedKey] === item[checkedKey])
              "
              :value="
                checkedList.find((c) => c[checkedKey] === item[checkedKey])
              "
            />
            <b
              class="checkmark"
              :class="{
                checked: checkedList.find(
                  (c) => c[checkedKey] === item[checkedKey]
                ),
              }"
            ></b>
          </label>
        </div>
      </div>
      <div
        class="mb-3 mb-md-0"
        :class="`
      ${headers[0].col ? `col-${headers[0].col}` : ''}
      ${headers[0].sm ? `col-sm-${headers[0].sm}` : ''}
      ${headers[0].md ? `col-md-${headers[0].md}` : ''}
      ${headers[0].lg ? `col-lg-${headers[0].lg}` : ''}
      ${headers[0].xl ? `col-xl-${headers[0].xl}` : ''}
      ${headers[0].xxl ? `col-xxl-${headers[0].xxl}` : ''}
      ${scrollLeft > 10 ? 'shadow-right' : ''}
      `"
      >
        <slot
          :item="item"
          :index="i"
          :name="headers[0].value"
          v-if="$scopedSlots[headers[0].value]"
        ></slot>
        <div v-else>
          {{ item[headers[0].value] }}
        </div>
      </div>
      <div
        @scroll="SET_SCROLL_EVENT"
        :data-item="i"
        class="d-flex w-100 flex-column flex-md-row px-3 px-md-0 align-items-center overflow-x-scroll"
      >
        <div
          v-for="(header, j) in headers.slice(1)"
          :key="i + '-' + j"
          class=""
          :class="`
              ${header.col ? `col-${header.col}` : ''}
              ${header.sm ? `col-sm-${header.sm}` : ''}
              ${header.md ? `col-md-${header.md}` : ''}
              ${header.lg ? `col-lg-${header.lg}` : ''}
              ${header.xl ? `col-xl-${header.xl}` : ''}
              ${header.xxl ? `col-xxl-${header.xxl}` : ''}`"
        >
          <slot
            :item="item"
            :index="i"
            :name="header.value"
            v-if="$scopedSlots[header.value]"
          ></slot>
          <div v-else>
            {{ item[header.value] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      default: () => [],
    },
    headers: {
      default: () => [],
    },
    checkedList: {
      default: () => [],
    },
    hideHeader: {
      default: false,
    },
    allChecked: {
      default: false,
    },
    checkable: {
      default: false,
    },
    singleCheckable: {
      default: false,
    },
    scrollLeft: {
      default: 0,
    },
    checkedKey: {
      default: "id",
    },
  },
  methods: {
    SET_SCROLL_EVENT(e) {
      this.$emit("change:scroll", e.target.scrollLeft);
      document.getElementById("table-header").scrollLeft = e.target.scrollLeft;
      const bodies = document.querySelectorAll(".overflow-x-scroll");
      for (let i = 0; i < bodies.length; i++) {
        const el = bodies[i];
        el.scrollLeft = e.target.scrollLeft;
      }
    },
  },
};
</script>

<style></style>
